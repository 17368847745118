import TopBar from '@/components/common/TopBar';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Container, Link, Typography } from '@mui/material';
import { continuationPath } from '@/utils/login-continuation-path'

export interface LayoutProps  {
   children: React.ReactNode;
   pageTitle?: string;
}

import { selectIsUserLoggedIn, selectIsUserPending, selectUser } from '@/store/selectors/user';
import FullScreenLoadingModal from '../common/FullScreenLoadingModal';
import InactiveMessageModal from '../common/InactiveMessageModal';
import SessionExpiredModal from '../common/SessionExpiredModal';
import SplashScreen from './SplashScreen';
import VideoModal from '../common/VideoModal';
import DrawerMenu from '../common/DrawerMenu';
import BreadCrumb from '../common/BreadCrumb';

export default function Layout(props: LayoutProps) {
  const router = useRouter();

  const isAuthPage = router.pathname.includes("login") || router.pathname.includes("signup") || router.pathname.includes("disclaimer");
  const isAuthOrSplashPage = isAuthPage || router.pathname === "/";

  const DefaultLayout = () => {
    const user = useSelector(selectUser);
    const isLoggedIn = useSelector(selectIsUserLoggedIn);
    const isUserLoading = useSelector(selectIsUserPending);
    const isInSplashScreen = router.pathname === "/";
    const isThoughtfulUser = user && (user.email.endsWith("@thoughtful.ai") || user.email.endsWith("@thoughtfulautomation.com"));
    const maxIdleTimeInMinutes = isThoughtfulUser ?  720 : 90;

    useEffect(() => {
      if (!isLoggedIn && !isUserLoading && !isAuthPage) {
        router.push('/login?originalURL=' + router.asPath);
      }
      else if(isLoggedIn && isAuthOrSplashPage) {
        router.push(continuationPath(router));
      }
    }, [isLoggedIn, isUserLoading]);

    if(!isLoggedIn || isInSplashScreen) return (
      <SplashScreen/>
    );

    return (
      <>
        <TopBar />
        <main>
          <BreadCrumb/>
          {props.children}
        </main>
        <SessionExpiredModal/>
        <InactiveMessageModal maxIdleTimeInMinutes={maxIdleTimeInMinutes} />
        <FullScreenLoadingModal/>
        <VideoModal />
        <DrawerMenu/>
      </>
    )
  }

  const AuthPageLayout = () => (
    <>
      <Container sx={{display:"flex", flexDirection: "column", alignItems:"center", marginTop: "5rem"}}>
        <Card className="loginPanel">
            {props.children}
        </Card>
        <div className="loginFooter">
          <Typography variant="body1">
            Having trouble signing in? &nbsp;
            <Link href="mailto:care@thoughtful.ai">Contact us</Link>
          </Typography>

          <Typography variant="footerLinks">
            <Link href="https://www.thoughtful.ai/privacy-policy" color="info.main">
                Privacy Policy
            </Link>
            <Link href="https://www.thoughtful.ai/terms-of-service" color="info.main">Terms of Service</Link>
            <Link href="https://www.thoughtful.ai/disclaimer" color="info.main">Disclaimer</Link>
          </Typography>
        </div>
      </Container>
    </>
  )

  return isAuthPage ? AuthPageLayout() : DefaultLayout()
}
