import { CircularProgress } from '@mui/material'
import React from 'react'

const RefreshingLoader = () => {
  return (
    <CircularProgress sx={{ margin: "0 auto", display: "block" }} />
  )
}

export default RefreshingLoader
